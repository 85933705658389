.redem-home {
  min-height: 100vh;
  position: relative;
  font-size: 16px;
  background-color: #3c4043;
}

.voucher-section {
  width: 40%;
  float: left;
  min-height: 100vh;
  background-color: #3c4043;
  display: flex;
  align-items: center;
  padding: 30px;
  justify-content: flex-end;
}
.voucher-entry {
  width: 300px;
}
.voucher-entry .logo-redem {
  width: 280px;
  margin-bottom: 60px;
}

.read-carefully {
  color: white;
  width: 100%;
  font-size: 15px;
  padding-left: 28px;
  font-weight: 500;
  position: relative;
}
.read-carefully span {
  display: block;
  margin-top: 10px;
  font-weight: 700;
}
.read-carefully::before {
  position: absolute;
  content: "";
  left: 5px;
  top: 7px;
  height: 40px;
  width: 4px;
  background-color: white;
}
.read-carefully::after {
  position: absolute;
  content: "";
  left: 0px;
  border-radius: 50%;
  border: 2px solid #467b96;
  top: 2px;
  height: 15px;
  width: 15px;
  background-color: #3c4043;
}

.read-carefully.thank::before {
  content: none;
}

.read-carefully span:after {
  position: absolute;
  content: "";
  left: 0px;
  border-radius: 50%;
  border: 2px solid #467b96;
  top: 38px;
  height: 15px;
  width: 15px;
  background-color: #00002e;
}

.voucher-input {
  margin-top: 30px;
  position: relative;
}
.voucher-input img {
  position: absolute;
  width: 28px;
  left: 20px;
  top: 10px;
}
.voucher-input input {
  width: 100%;
  height: 50px;
  border-radius: 100px;
  padding-left: 55px;
  outline: none !important;
  box-shadow: none;
  border: 0 !important;
}

.info-check {
  color: white;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.captch-redemp {
  margin-bottom: 30px;
}

.form-check.info-check .form-check-input {
  margin-top: 2.9px !important;
}

.voucher-entry input[type="submit"] {
  color: white;
  background: #467b96;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  height: 43px;
  width: 123px;
  border-radius: 100px;
  font-size: 16px;
  transition: all 0.3s;
}
.voucher-entry input[type="submit"]:hover {
  background: white;
  color: #467b96;
  transition: all 0.3s;
  font-weight: 500;
}

.infor-section {
  width: 60%;
  min-height: 100vh;
  background-color: white;
  float: left;
  display: flex;
  align-items: center;
  padding: 30px;
  justify-content: flex-start;
}
.infor-section ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.infor-section ul li {
  position: relative;
  padding-left: 100px;
  padding-bottom: 60px;
}
.infor-section ul li.active {
  padding-bottom: 40px;
}

.infor-section ul li::before {
  position: absolute;
  left: 75px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #017ea6;
  content: "";
}

.infor-section ul li .info-icon {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  padding: 11px;
  box-shadow: 0px 0px 14px #e1e1e1;
  position: absolute;
  top: -15px;
  left: 0;
}
.infor-section ul li .info-icon img {
  height: 100%;
  object-fit: contain;
  vertical-align: bottom;
}

.info-text {
  max-width: 575px;
  line-height: 23px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.info-text::before {
  position: absolute;
  top: 0;
  left: -33px;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #467b96;
  content: "";
  border-radius: 50%;
}

.infor-section ul li.active .info-text::before {
  background-color: #467b96;
  border-color: white;
  border-width: 3px;
  outline: 2px solid #467b96;
}

.info-text h5 {
  font-size: 18px;
  margin-bottom: 7px;
}
.info-text a {
  text-decoration: none;
  color: #467b96;
}
.info-text span {
  display: block;
  margin-top: 7px;
}

.thank-head {
  color: #467b96;
  font-size: 44px;
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.thank-img {
  display: block;
  margin: 0 auto;
}

.cert-detail-img {
  background: white;
  width: 83%;
  padding: 17px;
  height: 155px;
  border-radius: 10px;
}
.cert-detail-img .thank-img {
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cert-detail-img .pirds {
  width: 100%;
  height: 100%;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cert-detail-img .rdcrdtop table tr td {
  font-size: 18px;
  height: auto;
  line-height: 23px;
  padding: 10px;
  padding-top: 0;
}
.certi-info-box.userinfo-box .user-forminfo {
  min-width: 226px;
  margin-bottom: 15px;
}
.userinfo-box.certi-info-box {
  margin-bottom: -5px;
}

.back-certi {
  background: #017ea6;
  border: 0 !important;
  border-radius: 100px;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 16px;
  height: 43px;
  outline: none !important;
  transition: all 0.3s;
  width: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-certi:hover {
  background: #467b96;
}

.info-text.buton-li:before {
  top: 10px;
}

.info-text h6 {
  font-weight: 700;
  font-size: 16px;
}
.info-text h6 span {
  color: #467b96;
  margin: 0;
  display: initial;
  font-weight: 500;
}

.userinfo-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.userinfo-box .user-forminfo {
  font-weight: 400;
  width: max-content;
  padding-right: 25px;
  padding-left: 18px;
  position: relative;
}

.userinfo-box .user-forminfo:before {
  content: "";
  height: 10px;
  width: 10px;
  background: #467b96;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 5px;
}

.userinfo-box .user-forminfo span {
  margin: 0;
  display: initial;
  font-weight: 600;
}

.infor-section.thankyou ul li::before {
  left: 50px;
  width: 3px;
}
.infor-section.thankyou ul li {
  padding-left: 75px;
  padding-bottom: 25px;
}
.infor-section.thankyou ul li .info-text {
  max-width: 690px;
  padding-left: 10px;
}
.infor-section .new-footer {
  padding-left: 73px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 25px;
}
.infor-section.thankyou .new-footer {
  padding-left: 48px;
}
.infor-section .new-footer p {
  margin: 0;
}

button#pop-close1 {
  background-color: black;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: -20px;
  margin-top: -21px;
  padding: 0;
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  width: 30px;
}
#header {
  background-color: #fff !important;
  border-bottom: none !important;
  padding: 18px;
}

img#popImg {
  align-items: center;
  margin: auto;
  width: 300px;
}
div#modelContent .model-body {
  font-family: Raleway, sans-serif !important;
  padding-bottom: 15px;
  padding-right: 36px;
  padding-top: 15px;
}

div#modelContent .model-body .llist {
  text-align: center;
}
div#modelContent .model-body .llist {
  font-weight: 400;
  list-style: none;
  text-align: center;
}
div#modelContent .model-body li {
  font-size: 14px;
  line-height: 22px;
}
div#modelContent .modal-content {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}
div#modelContent {
  max-width: 910px;
  margin: 1.75rem auto !important;
  font-size: 14px;
  color: #212529;
}
.term-model .model-body {
  color: #000;
  font-family: Raleway, sans-serif !important;
  font-size: 14px;
  padding: 15px;
}
.term-model .model-body ul.terms-list {
  list-style: none;
  padding-left: 15px;
}
.term-model .model-body ul.terms-list LI {
  font-weight: 400;
  list-style-image: URL("../../public/assets/images/arrow-right.png");
}
.term-model .modal-header {
  align-items: center;
  background-color: var(--primary-color) !important;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  color: #fff;
  display: flex;
  flex-shrink: 0;
  font-family: Raleway, sans-serif !important;
  justify-content: space-between;
  padding: 1rem;
}

button#pop-close {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 50%;
}
button#pop-close {
  align-items: center;
  display: flex;
  font-size: 28px;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.fix-white {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 12000122222222222222222222222222222200000000000000000000000000000099999999999;
  background-color: white;
}
.fix-white.hide {
  display: none;
  z-index: -10;
}

#notfound {
  background: #fff;
  bottom: 0;
  font-family: Roboto, sans-serif !important;
  left: 0;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 111111111111111;
}
#notfound .notfound {
  left: 50%;
  line-height: 1.4;
  max-width: 920px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
#notfound .notfound .notfound-404 {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

#notfound .notfound .notfound-404 h1,
#notfound .notfound h2 {
  font-family: Roboto, sans-serif !important;
  font-weight: 900;
  margin: 0;
}

#notfound .notfound .notfound-404 h1 {
  color: #ececec;
  font-size: 276px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#notfound .notfound h2 {
  color: #000;
  font-size: 46px;
  text-transform: uppercase;
}
#notfound .notfound p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  text-transform: uppercase;
}
#notfound .notfound button {
  background: var(--secondary-color);
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 16px 38px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
}
#notfound .notfound button:hover {
  background-color: #fff;

  border-color: var(--secondary-color);

  color: var(--secondary-color);
}

@media (min-width: 768px) and (max-width: 992px) {
  div#modelContent {
    max-width: 689px;
    font-size: 13px;
  }
  div#modelContent .model-body li {
    line-height: 18px !important;
    font-size: 13px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  div#modelContent {
    max-width: 90% !important;
    margin: 1.75rem auto !important;

    font-size: 11px;
  }
  div#modelContent .model-body li {
    line-height: 14px !important;
    font-size: 11px !important;
  }

  img#popImg {
    width: 200px;
    align-items: center;
    margin: auto;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .voucher-section {
    width: 100%;
    float: none;
    justify-content: center;
    min-height: 60vh;
  }
  .infor-section {
    width: 100%;
    float: none;
    min-height: auto;
  }
  .info-text {
    max-width: 90%;
    word-break: break-word;
  }
  .infor-section.thankyou ul li .info-text {
    max-width: 90%;
    padding-left: 0;
  }

  .infor-section.thankyou ul li::before {
    left: -5px;
  }
  .infor-section.thankyou ul li {
    padding-left: 20px;
  }
  .userinfo-box {
    display: block;
  }
  .infor-section.thankyou .new-footer {
    padding-left: 0;
    margin-left: -5px;
  }
}

@media only screen and (max-width: 480px) {
  #notfound .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  #notfound .notfound h2 {
    font-size: 26px;
  }
  #notfound .notfound p {
    font-size: 14px;
  }
  #notfound .notfound button {
    font-size: 12px;
    padding: 5px 20px;
  }
}
